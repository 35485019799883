<template>
  <div>
    <!--begin::customer-->
    <div>
          <h3 class="card-label">
            {{ $t('workstation.workstation') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>



      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('workstation.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control"
                       :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('workstation.manager') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect
                      v-model="manager"
                      :class="validation && validation.manager_id ? 'is-invalid' : ''"
                      :placeholder="$t('workstation.manager')"
                      label="name"
                      track-by="id"
                      :options="users"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getUsers($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus"
                                                                                                     style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters')+' '+$t('workstation.manager') }}.</span>
                <span v-if="validation && validation.manager_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.manager_id[0] }}
                            </span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.electricity_cost_per_hour') }}</label>
                <input type="number" min="0" v-model="data.electricity_cost_per_hour" @input="sumNet" class="form-control"
                       :class="validation && validation.electricity_cost_per_hour ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.electricity_cost_per_hour"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.electricity_cost_per_hour[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.rent_cost_per_hour') }}</label>
                <input type="number" min="0" v-model="data.rent_cost_per_hour" @input="sumNet" class="form-control"
                       :class="validation && validation.rent_cost_per_hour ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.rent_cost_per_hour"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.rent_cost_per_hour[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.consumable_cost_per_hour') }}</label>
                <input type="number" min="0" v-model="data.consumable_cost_per_hour" @input="sumNet" class="form-control"
                       :class="validation && validation.consumable_cost_per_hour ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.consumable_cost_per_hour"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.consumable_cost_per_hour[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.wages_per_hour') }}</label>
                <input type="number" min="0" v-model="data.wages_per_hour" @input="sumNet" class="form-control"
                       :class="validation && validation.wages_per_hour ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.wages_per_hour"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.wages_per_hour[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.net_rate_per_hour') }}</label>
                <input type="number" min="0" v-model="data.net_rate_per_hour" disabled class="form-control"
                       :class="validation && validation.net_rate_per_hour ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.net_rate_per_hour"
                      class="fv-plugins-message-container invalid-feedback">
                                {{ validation.net_rate_per_hour[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('workstation.work_days') }}</label>
                <multiselect
                    v-model="data.work_days"
                    :placeholder="$t('workstation.work_days')"
                    label="title"
                    track-by="id"
                    :options="work_days_list"
                    :multiple="true"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.work_days" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.work_days[0] }}
                            </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('branch') }}</label>
                <div class="input-group mb-3">
                  <multiselect v-model="branch"
                               :placeholder="$t('branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus"
                                                                                                   style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.branch_id[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mt-10 mb-10">
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>
                  {{ $t('workstation.is_active') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12 mb-5 mt-5">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('workstation.working_hours') }}</h6>
            <button type="button" class="btn btn-primary" @click="addRepeater"><i
                class="fas fa-plus"></i>{{ $t('add_more') }}
            </button>
          </div>
          <div class="bg-white">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">{{ $t('workstation.start_time') }}</th>
                <th scope="col">{{ $t('workstation.end_time') }}</th>
                <th scope="col">{{ $t('workstation.is_active') }}</th>
                <th scope="col">{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in working_hours" :key="index">
                <td>
                  <input type="time" v-model="item.start_time" class="form-control"
                         :placeholder="$t('workstation.start_time')"/>
                </td>
                <td>
                  <input type="time" v-model="item.end_time" class="form-control"
                         :placeholder="$t('workstation.end_time')"/>
                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="item.is_active" name="check-button" switch>
                    {{ $t('workstation.is_active') }}
                  </b-form-checkbox>
                </td>
                <td>
                  <button type="button" class="btn btn-link mr-2" @click="removeRepeater(index)"><i
                      class="fas fa-trash text-danger"></i></button>
                </td>
              </tr>


              </tbody>
            </table>
          </div>

        </div>
      </div>

        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-workstation",
  data() {
    return {
      mainRoute: 'manufacturers/workstations',
      mainRouteDependency: 'base/dependency',


      data: {
        name: null,
        manager_id: null,
        branch_id: null,
        electricity_cost_per_hour: 0,
        rent_cost_per_hour: 0,
        consumable_cost_per_hour: 0,
        wages_per_hour: 0,
        net_rate_per_hour: 0,
        work_days: [],
        is_active: true,
      },
      working_hours: [],


      working_hours_repeater: {id: null, start_time: null, end_time: null, is_active: true},
      manager: null,
      users: [],
      work_days_list: [],

      branch: null,
      branches: [],

      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    manager: function (val) {
      if (val)
        this.data.manager_id = val.id;
      else
        this.data.manager_id = null;
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
        working_hours: this.working_hours,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'workstation.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
        working_hours: this.working_hours,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'workstation.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.manager_id = response.data.data.manager_id;
        this.manager = response.data.data.manager;
        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        this.data.electricity_cost_per_hour = response.data.data.electricity_cost_per_hour;
        this.data.rent_cost_per_hour = response.data.data.rent_cost_per_hour;
        this.data.consumable_cost_per_hour = response.data.data.consumable_cost_per_hour;
        this.data.wages_per_hour = response.data.data.wages_per_hour;
        this.data.net_rate_per_hour = response.data.data.net_rate_per_hour;
        this.data.work_days = response.data.data.work_days;
        this.data.is_active = response.data.data.is_active;

        if (response.data.data.working_hours && response.data.data.working_hours.length > 0) {
          this.working_hours = response.data.data.working_hours;
        } else {
          this.addRepeater();
        }


      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getWeekDays() {
      ApiService.get(this.mainRouteDependency + "/week_days").then((response) => {
        this.work_days_list = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
        // this.branch = this.branches.find(option => option.is_default == 1);
      });
    },
    addRepeater() {
      this.working_hours.unshift(this.working_hours_repeater);
      this.working_hours_repeater = {id: null, start_time: null, end_time: null, is_active: true};
    },
    removeRepeater(index) {
      if (this.working_hours.length > 1)
        this.working_hours.splice(index, 1);
    },
    sumNet() {
      this.data.net_rate_per_hour = parseFloat(this.data.electricity_cost_per_hour) + parseFloat(this.data.rent_cost_per_hour) +
          parseFloat(this.data.consumable_cost_per_hour) + parseFloat(this.data.wages_per_hour);
    },
    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.branch = response.data.data.branch;
        this.data.branch_id = response.data.data.branch_id;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.workstation"),
      route: '/manufactories/workstation'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getWeekDays();
    this.getBranches();

    if (this.idEditing) {
      this.getData();
    } else {
      this.defaultDataForUser();
      this.addRepeater();
    }

  },
};
</script>